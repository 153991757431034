<template>
    <div class="blog">
        <div class="container">
            <div class="row">
                <div class="col-md-8">
                    <b-card no-body class="overflow-hidden">
                        <b-row no-gutters>
                            <b-col md="6">
                                <b-card-img
                                    src="https://scontent.fkrt3-1.fna.fbcdn.net/v/t1.0-9/s960x960/93076584_2875297375893179_8277215506327404544_o.jpg?_nc_cat=100&ccb=3&_nc_sid=8024bb&_nc_ohc=DtA6j7ZHtQYAX9pF4Vs&_nc_ht=scontent.fkrt3-1.fna&tp=7&oh=18986d3d7ae9899950b302aa3c4815c2&oe=604DB060"
                                    alt="Image" class="rounded-0">
                                </b-card-img>
                            </b-col>
                            <b-col md="6">
                                <b-card-body title="الدم هو البجمعنا">
                                    <b-card-text>
                                        الدم هو البجمعنا. لا بفرِّق بين لون ولا دين ولا عرق.
                                        لانو الدم حياة... ،♥️
                                        <p style="padding-top:5%">
                                            <Button to="/post">المزيد</Button>
                                        </p>
                                    </b-card-text>
                                </b-card-body>
                            </b-col>
                        </b-row>
                    </b-card>
                    <hr>

                    <b-card no-body class="overflow-hidden">
                        <b-row no-gutters>
                            <b-col md="6">
                                <b-card-img
                                    src="https://scontent.fkrt3-1.fna.fbcdn.net/v/t1.0-9/s960x960/93076584_2875297375893179_8277215506327404544_o.jpg?_nc_cat=100&ccb=3&_nc_sid=8024bb&_nc_ohc=DtA6j7ZHtQYAX9pF4Vs&_nc_ht=scontent.fkrt3-1.fna&tp=7&oh=18986d3d7ae9899950b302aa3c4815c2&oe=604DB060"
                                    alt="Image" class="rounded-0">
                                </b-card-img>
                            </b-col>
                            <b-col md="6">
                                <b-card-body title="الدم هو البجمعنا">
                                    <b-card-text>
                                        الدم هو البجمعنا. لا بفرِّق بين لون ولا دين ولا عرق.
                                        لانو الدم حياة... ،♥️
                                        <p style="padding-top:5%">
                                            <Button to="/post">المزيد</Button>
                                        </p>
                                    </b-card-text>
                                </b-card-body>
                            </b-col>
                        </b-row>
                    </b-card>
                    <hr>

                    <b-card no-body class="overflow-hidden">
                        <b-row no-gutters>
                            <b-col md="6">
                                <b-card-img
                                    src="https://scontent.fkrt3-1.fna.fbcdn.net/v/t1.0-9/s960x960/93076584_2875297375893179_8277215506327404544_o.jpg?_nc_cat=100&ccb=3&_nc_sid=8024bb&_nc_ohc=DtA6j7ZHtQYAX9pF4Vs&_nc_ht=scontent.fkrt3-1.fna&tp=7&oh=18986d3d7ae9899950b302aa3c4815c2&oe=604DB060"
                                    alt="Image" class="rounded-0">
                                </b-card-img>
                            </b-col>
                            <b-col md="6">
                                <b-card-body title="الدم هو البجمعنا">
                                    <b-card-text>
                                        الدم هو البجمعنا. لا بفرِّق بين لون ولا دين ولا عرق.
                                        لانو الدم حياة... ،♥️
                                        <p style="padding-top:5%">
                                            <Button to="/post">المزيد</Button>
                                        </p>
                                    </b-card-text>
                                </b-card-body>
                            </b-col>
                        </b-row>
                    </b-card>
                    <hr>

                    <b-card no-body class="overflow-hidden">
                        <b-row no-gutters>
                            <b-col md="6">
                                <b-card-img
                                    src="https://scontent.fkrt3-1.fna.fbcdn.net/v/t1.0-9/s960x960/93076584_2875297375893179_8277215506327404544_o.jpg?_nc_cat=100&ccb=3&_nc_sid=8024bb&_nc_ohc=DtA6j7ZHtQYAX9pF4Vs&_nc_ht=scontent.fkrt3-1.fna&tp=7&oh=18986d3d7ae9899950b302aa3c4815c2&oe=604DB060"
                                    alt="Image" class="rounded-0">
                                </b-card-img>
                            </b-col>
                            <b-col md="6">
                                <b-card-body title="الدم هو البجمعنا">
                                    <b-card-text>
                                        الدم هو البجمعنا. لا بفرِّق بين لون ولا دين ولا عرق.
                                        لانو الدم حياة... ،♥️
                                        <p style="padding-top:5%">
                                            <Button to="/post">المزيد</Button>
                                        </p>
                                    </b-card-text>
                                </b-card-body>
                            </b-col>
                        </b-row>
                    </b-card>
                    <hr>
                    <div class="pages">
                        <Page :total="100" />
                    </div>
                </div>
                <div class="col-md-4">

                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: '',
        data() {
            return {

            }
        }
    }
</script>

<style lang="scss">
    $secound_color : #F0F6F7FF;

    body {
        direction: rtl;
        background-color: $secound_color;

        .blog {
            margin-top: 20px;
        }

        hr {
            margin: 3% 0;
        }

        .pages {
            margin: 5% 0;
        }
    }
</style>